





















import { Component, Prop, Vue } from 'vue-property-decorator';
import ClassConverter from '@/service/classConverter';
import Filters from '@/models/filters';

@Component
export default class CodeOutput extends Vue {
  @Prop()
  code!: string;

  @Prop()
  filters!: Filters;

  private placeholder = `export interface Person {
  name: string;
  addresses: Address[];
}

export interface Employee extends Person {
  salary: number;
}

export interface Address {
  street: string;
}

export enum PersonType {
  Employee = 1,
  Client = 2,
}`;

  download(): void {
    const blob = new Blob([this.output], { type: 'text/plain' });
    const elem = window.document.createElement('a');
    elem.href = window.URL.createObjectURL(blob);
    elem.download = 'types.ts';
    document.body.appendChild(elem);
    elem.click();
    document.body.removeChild(elem);
  }

  get output(): string {
    return ClassConverter.Convert(this.code, this.filters);
  }
}
