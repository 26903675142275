




























































import { Component, Prop, Vue } from 'vue-property-decorator';
import { InputType } from '@/models/inputType';

@Component
export default class CodeInput extends Vue {
  @Prop()
  value!: string;

  private InputType = InputType;

  private inputType = InputType.PasteCode;

  private dragover = false;

  private timeout = 0;

  private files: File[] = [];

  private placeholder = `public class Person {
    public string Name { get; set; }
    public List<Address> Addresses { get; set; }
}

public class Employee : Person {
    public decimal Salary { get; set; }
}

public class Address {
    public string Street { get; set; }
}

public enum PersonType {
    Employee = 1,
    Client = 2
}`;

  // eslint-disable-next-line class-methods-use-this
  browseFiles(): void {
    const selectedFile = document.getElementById('selectedFile');
    if (selectedFile) {
      selectedFile.click();
    }
  }

  uploadFiles(event: DragEvent): void {
    let files: FileList | null = event.target
      ? (event.target as HTMLInputElement).files : null;
    if (!files && event.dataTransfer) {
      files = event.dataTransfer.files;
    }

    if (files && files.length > 0) {
      for (let i = 0; i < files.length; i += 1) {
        this.files.push(files[i]);
      }
      this.readFiles();
    }
  }

  removeFile(file: File): void {
    this.files = this.files.filter((f) => f !== file);
    this.readFiles();
  }

  readFiles(): void {
    this.code = '';
    this.files.forEach((file) => {
      const fileReader = new FileReader();
      fileReader.onload = () => {
        this.code += `${fileReader.result}\r\n`;
      };
      fileReader.readAsText(file);
    });
  }

  draggedOver(event: DragEvent): void {
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const that = this;
    that.dragover = true;

    if (event.dataTransfer) {
      // eslint-disable-next-line no-param-reassign
      event.dataTransfer.dropEffect = 'copy';
    }

    if (that.timeout) {
      clearTimeout(that.timeout);
    }
    that.timeout = setTimeout(() => {
      that.dragover = false;
    }, 250);
  }

  get code(): string {
    return this.value;
  }

  set code(value: string) {
    this.$emit('input', value);
  }
}
