

















import { Component, Prop, Vue } from 'vue-property-decorator';
import Filters from '@/models/filters';

@Component
export default class CodeFilters extends Vue {
  @Prop()
  value!: Filters;

  get filters(): Filters {
    return this.value;
  }

  set filters(value: Filters) {
    this.$emit('input', value);
  }
}
