















import { Component, Vue } from 'vue-property-decorator';
import CodeInput from '@/components/CodeInput.vue';
import CodeOutput from '@/components/CodeOutput.vue';
import CodeFilters from '@/components/CodeFilters.vue';
import Filters from '@/models/filters';

@Component({
  components: {
    CodeInput,
    CodeOutput,
    CodeFilters,
  },
})
export default class Home extends Vue {
  private filters: Filters = {
    interfacePrefix: false,
    upperCamelCase: false,
  };

  private code = '';
}
